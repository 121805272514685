<template>
  <b-container class="main-content-container px-4 pb-4" fluid>
    <b-row class="page-header py-4" no-gutters>
      <b-col class="text-center text-sm-left mb-4 mb-sm-0" col sm="4">
        <span class="text-uppercase page-subtitle">Vehicle view/edit</span>
        <h3 class="page-title">Vehicle</h3>
      </b-col>
    </b-row>
    <b-card>
      <view-vehicle-component ref="view"/>
    </b-card>
  </b-container>
</template>

<script>
import ViewVehicleComponent from '@/components/vehicles/ViewVehicle.vue';

export default {
  name: 'ViewEdit',
  components: {ViewVehicleComponent},
  data: () => ({
    id: null
  }),
  mounted() {
    this.id = this.$route.params.id;
    this.load();
  },
  methods: {
    load() {
      this.$refs.view.loadVehicle(this.id);
    }
  },
};
</script>

<style scoped>

</style>
